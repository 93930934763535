.Splash {
  background: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
  padding: 0 30px;
}

.Splash .logo {
  max-width: 80%;
  margin-bottom: 40px;
}


.Splash .journey-title h1 {
  font-family: 'Libre Franklin', sans-serif !important;
  font-size: 20px !important;
  font-weight: normal !important;
  text-transform: none !important;
}

.Splash a {
  text-decoration: none;
  color: black;
}

.Splash .button {
  margin-top: 50px;
  height: 45px;
  width: 200px;
  border: 2px solid black;
  border-radius: 45px;
  line-height: 45px;
  font-weight: bold;
}

.Splash footer {
  position: absolute;
  bottom: 20px;
}

.Splash footer h4 {
  margin: 5px 0 0 0;
}


