@import url('https://fonts.googleapis.com/css?family=Libre+Franklin:300,600,700|Space+Mono:700');

html {
  box-sizing: border-box;
}
* {
  box-sizing: inherit;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Libre Franklin', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body, #root, .app {
  position: relative;
  width: 100%;
  height: 100%;
}

h1, h2, h3, h4 {
  font-family: 'Space Mono', monospace;
  text-transform: uppercase;
  font-weight: bold;
}
h1 {
  line-height: 2rem;
  display: inline-block;
}
h2 {
  line-height: 1.5rem;
  display: inline-block;
}

/* .header {
  height: 80px;
  overflow: auto;
  background: #aaa;
} */
.container {
  /* height: 500px; */
  position: absolute;
  top: 50px;
  /* top: 0; */
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 0 20px;
}
.container.relative {
  /* overflow-y: auto; */
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
}
