.StickyJourneyOverview {
  width: 100%;
  max-width: 500px;
  height: 50px;
  position: fixed;
  top: 0;
  z-index: 999;
  background: white;
  padding: 0 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.StickyJourneyOverview .line {
  width: 100%;
  height: 2px;
  position: absolute;
  top: 22px;
  background: black;
  z-index: -2;
  /* top: 20px; */
}

.StickyJourneyOverview a {
  text-decoration: none;
  position: relative;
}

.StickyJourneyOverview a:first-of-type::before,
.StickyJourneyOverview a:last-of-type::after {
  /* border: 1px solid rosybrown; */
  content: "";
  position: absolute;
  height: 100%;
  width: 200px;
  background: white;
  z-index: -1;
}

.StickyJourneyOverview a:first-of-type::before {
  left: -190px;
}
.StickyJourneyOverview a:last-of-type::after {
  right: -190px;
}

.StickyJourneyOverview a::after {
  content: "";
  position: absolute;
}

.StickyJourneyOverview .circle {
  width: 25px;
  height: 25px;
  display: inline-block;
  border: 2px solid black;
  border-radius: 100%;
  background: white;
  transition: all 0.4s ease-in-out;
}

.StickyJourneyOverview .circle.summary-circle {
  border-radius: 0;
  background: repeating-linear-gradient(45deg, white, white 5px, lightgray 5px, lightgray 10px);
}

.StickyJourneyOverview .circle.summary-arrow {
  background: url('./arrow.svg') no-repeat;
  background-size: 90%;
  border: none;
  border-radius: 0;
}

.StickyJourneyOverview .circle.summary-arrow.active {
  background: url('./arrow-filled.svg') no-repeat;
  background-size: 90%;
  border: none;
  border-radius: 0;
}

.StickyJourneyOverview .circle.active {
  background: black;
}

.StickyJourneyOverview span {
  font-size: 30px;
}

/* .StickyJourneyOverview span.active {
  color: red;
} */
