.Journey {
  position: relative;
  margin: auto;
  height: 100%;
  width: 100%;
  max-width: 500px;
}

.Journey img,
.Journey video {
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Journey img[alt*="fullwidth"],
.Journey video,
.Journey iframe {
  max-width: none;
  margin-left: -20px;
  width: calc(100% + 40px) !important;
}

.Journey blockquote {
  margin: 0 0 0 -20px;
  width: calc(100% + 40px);
  background: rgba(217,243,255, 0.5);
  padding: 60px;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  /* text-transform: uppercase; */
}

.Journey blockquote p {
  margin: 0;
}

.Journey blockquote::before,
.Journey blockquote::after {
  position: absolute;
  font-size: 50px;
  font-weight: bold;
}

.Journey blockquote::before {
  content: "“";
  top: 30px;
  left: 30px;
}
.Journey blockquote::after {
  content: "”";
  bottom: 30px;
  right: 30px;
}

.Journey footer {
  width: calc(100% + 40px);
  margin: 50px 0 0 -20px;
  background: black;
  height: 150px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
