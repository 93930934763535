.ActivityHeader.sticky {
  margin: 30px 0;
}

.ActivityHeader .sticky-header {
  overflow: auto;
  /* background: #d9f3ff; */
  background: #c3f7e1;
  padding: 0 25px;
  margin: 0 0 0 -20px;
  width: calc(100% + 40px) !important;
  max-width: 500px;
  position: relative;
  font-family: 'Space Mono', monospace;
  text-transform: uppercase;
  font-weight: bold;
  z-index: 999;
}

.ActivityHeader .sticky-header.summary {
  background: #585858;
  color: white;
}

.ActivityHeader .sticky-header.big,
.ActivityHeader .sticky-header.small {
  height: 55px;
}

/* .ActivityHeader .sticky-header.small {
  height: 45px;
}

.ActivityHeader .sticky-header.big {
  height: 90px;
} */

.ActivityHeader .sticky-header * {
  margin: 0;
  padding: 0;
}

.ActivityHeader .sticky-header.big *,
.ActivityHeader .sticky-header.small * {
  font-size: 25px;
  line-height: 55px;
  width: 100% !important;
}


/* .ActivityHeader .sticky-header.small * {
  font-size: 18px;
  line-height: 45px;
  width: 100% !important;
}

.ActivityHeader .sticky-header.big * {
  font-size: 25px;
  line-height: 90px;
} */

.ActivityHeader .subheading {
  display: block;
  margin-top: 40px;
}

.ActivityHeader .subheading h1 {
  /* margin-top: -20px; */
  margin-bottom: 0;
}

.ActivityHeader .subheading img {
  display: inline-block;
  float: left;
  margin-right: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
}

