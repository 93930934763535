@import url(https://fonts.googleapis.com/css?family=Libre+Franklin:300,600,700|Space+Mono:700);
html {
  box-sizing: border-box;
}
* {
  box-sizing: inherit;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Libre Franklin', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body, #root, .app {
  position: relative;
  width: 100%;
  height: 100%;
}

h1, h2, h3, h4 {
  font-family: 'Space Mono', monospace;
  text-transform: uppercase;
  font-weight: bold;
}
h1 {
  line-height: 2rem;
  display: inline-block;
}
h2 {
  line-height: 1.5rem;
  display: inline-block;
}

/* .header {
  height: 80px;
  overflow: auto;
  background: #aaa;
} */
.container {
  /* height: 500px; */
  position: absolute;
  top: 50px;
  /* top: 0; */
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 0 20px;
}
.container.relative {
  /* overflow-y: auto; */
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Splash {
  background: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
  padding: 0 30px;
}

.Splash .logo {
  max-width: 80%;
  margin-bottom: 40px;
}


.Splash .journey-title h1 {
  font-family: 'Libre Franklin', sans-serif !important;
  font-size: 20px !important;
  font-weight: normal !important;
  text-transform: none !important;
}

.Splash a {
  text-decoration: none;
  color: black;
}

.Splash .button {
  margin-top: 50px;
  height: 45px;
  width: 200px;
  border: 2px solid black;
  border-radius: 45px;
  line-height: 45px;
  font-weight: bold;
}

.Splash footer {
  position: absolute;
  bottom: 20px;
}

.Splash footer h4 {
  margin: 5px 0 0 0;
}



.ActivityHeader.sticky {
  margin: 30px 0;
}

.ActivityHeader .sticky-header {
  overflow: auto;
  /* background: #d9f3ff; */
  background: #c3f7e1;
  padding: 0 25px;
  margin: 0 0 0 -20px;
  width: calc(100% + 40px) !important;
  max-width: 500px;
  position: relative;
  font-family: 'Space Mono', monospace;
  text-transform: uppercase;
  font-weight: bold;
  z-index: 999;
}

.ActivityHeader .sticky-header.summary {
  background: #585858;
  color: white;
}

.ActivityHeader .sticky-header.big,
.ActivityHeader .sticky-header.small {
  height: 55px;
}

/* .ActivityHeader .sticky-header.small {
  height: 45px;
}

.ActivityHeader .sticky-header.big {
  height: 90px;
} */

.ActivityHeader .sticky-header * {
  margin: 0;
  padding: 0;
}

.ActivityHeader .sticky-header.big *,
.ActivityHeader .sticky-header.small * {
  font-size: 25px;
  line-height: 55px;
  width: 100% !important;
}


/* .ActivityHeader .sticky-header.small * {
  font-size: 18px;
  line-height: 45px;
  width: 100% !important;
}

.ActivityHeader .sticky-header.big * {
  font-size: 25px;
  line-height: 90px;
} */

.ActivityHeader .subheading {
  display: block;
  margin-top: 40px;
}

.ActivityHeader .subheading h1 {
  /* margin-top: -20px; */
  margin-bottom: 0;
}

.ActivityHeader .subheading img {
  display: inline-block;
  float: left;
  margin-right: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
}


.StickyJourneyOverview {
  width: 100%;
  max-width: 500px;
  height: 50px;
  position: fixed;
  top: 0;
  z-index: 999;
  background: white;
  padding: 0 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.StickyJourneyOverview .line {
  width: 100%;
  height: 2px;
  position: absolute;
  top: 22px;
  background: black;
  z-index: -2;
  /* top: 20px; */
}

.StickyJourneyOverview a {
  text-decoration: none;
  position: relative;
}

.StickyJourneyOverview a:first-of-type::before,
.StickyJourneyOverview a:last-of-type::after {
  /* border: 1px solid rosybrown; */
  content: "";
  position: absolute;
  height: 100%;
  width: 200px;
  background: white;
  z-index: -1;
}

.StickyJourneyOverview a:first-of-type::before {
  left: -190px;
}
.StickyJourneyOverview a:last-of-type::after {
  right: -190px;
}

.StickyJourneyOverview a::after {
  content: "";
  position: absolute;
}

.StickyJourneyOverview .circle {
  width: 25px;
  height: 25px;
  display: inline-block;
  border: 2px solid black;
  border-radius: 100%;
  background: white;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.StickyJourneyOverview .circle.summary-circle {
  border-radius: 0;
  background: -webkit-repeating-linear-gradient(45deg, white, white 5px, lightgray 5px, lightgray 10px);
  background: repeating-linear-gradient(45deg, white, white 5px, lightgray 5px, lightgray 10px);
}

.StickyJourneyOverview .circle.summary-arrow {
  background: url(/static/media/arrow.67727dbe.svg) no-repeat;
  background-size: 90%;
  border: none;
  border-radius: 0;
}

.StickyJourneyOverview .circle.summary-arrow.active {
  background: url(/static/media/arrow-filled.92d2da1c.svg) no-repeat;
  background-size: 90%;
  border: none;
  border-radius: 0;
}

.StickyJourneyOverview .circle.active {
  background: black;
}

.StickyJourneyOverview span {
  font-size: 30px;
}

/* .StickyJourneyOverview span.active {
  color: red;
} */

.Journey {
  position: relative;
  margin: auto;
  height: 100%;
  width: 100%;
  max-width: 500px;
}

.Journey img,
.Journey video {
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Journey img[alt*="fullwidth"],
.Journey video,
.Journey iframe {
  max-width: none;
  margin-left: -20px;
  width: calc(100% + 40px) !important;
}

.Journey blockquote {
  margin: 0 0 0 -20px;
  width: calc(100% + 40px);
  background: rgba(217,243,255, 0.5);
  padding: 60px;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  /* text-transform: uppercase; */
}

.Journey blockquote p {
  margin: 0;
}

.Journey blockquote::before,
.Journey blockquote::after {
  position: absolute;
  font-size: 50px;
  font-weight: bold;
}

.Journey blockquote::before {
  content: "\201C";
  top: 30px;
  left: 30px;
}
.Journey blockquote::after {
  content: "\201D";
  bottom: 30px;
  right: 30px;
}

.Journey footer {
  width: calc(100% + 40px);
  margin: 50px 0 0 -20px;
  background: black;
  height: 150px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

